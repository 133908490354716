import React from 'react';
import Anasayfa  from './components/anasayfa';

export default function App() {

  return (
    <div className="anasayfa">
        <Anasayfa />
    </div>
  );
}