import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import htmlParse from "html-react-parser";

const truncate = (input, len) =>
  input.length > len
    ? `${input.substring(0, len)}...${input.substring(38)}`
    : input;

const disCon = () => window.location.reload();

export default function Anasayfa() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [mintAmount, setMintAmount] = useState(1);
  const [barImg, setBarImg] = useState("/img/bar/1.png");
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    MARKETPLACE_NFT: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME} ...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, " + err.message);
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        // //const tokenId = receipt.events.Transfer.returnValues.tokenId;
        // let reValue = "";
        // if (mintAmount > 1) {
        //     for (var i = 0; i < mintAmount; i++) {
        //         if (i == mintAmount - 1) {
        //             reValue += `<a href="${CONFIG.MARKETPLACE_NFT}/${receipt.events.Transfer[i].returnValues.tokenId}" target="_blank">${CONFIG.NFT_NAME} #${receipt.events.Transfer[i].returnValues.tokenId}</a>`;
        //         } else {
        //             reValue += `<a href="${CONFIG.MARKETPLACE_NFT}/${receipt.events.Transfer[i].returnValues.tokenId}" target="_blank">${CONFIG.NFT_NAME} #${receipt.events.Transfer[i].returnValues.tokenId}</a>, `;
        //         }
        //     }
        // } else {
        //     reValue = `<a href="${CONFIG.MARKETPLACE_NFT}/${receipt.events.Transfer.returnValues.tokenId}" target="_blank">${CONFIG.NFT_NAME} #${receipt.events.Transfer.returnValues.tokenId}</a>`;
        // }
        setFeedback(
          // //`The ${CONFIG.NFT_NAME} # ${tokenId} is yours! go visit Opensea.io to view it.`
          // `The ${reValue} is yours!<br />Go visit Unknown.io to view it.`
          `The <a href="${
            "https://bscscan.com/tx/" + receipt.transactionHash
          }" target="_blank">Transaction Hash</a> is yours!<br />Go visit bscscan.com to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
    setBarImg(`/img/bar/${newMintAmount}.png`);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 20) {
      newMintAmount = 20;
    }
    setMintAmount(newMintAmount);
    setBarImg(`/img/bar/${newMintAmount}.png`);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 800,
    centerMode: true,
    centerPadding: "5px",
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  console.log(
    "onlyWhitelisted:",
    data.onlyWhitelisted,
    " - isWhitelisted:",
    data.isWhitelisted
  );
  console.log("totalSupply:", data.totalSupply);
  console.log("maxSupply:", data.maxSupply);
  console.log("cost:", data.cost);

  return (
    <>
      <div id="perspective" className="perspective effect-moveleft">
        <div className="cont">
          <div className="wrapper">
            <header>
              <nav className="nav-bar-white navbar pull-left navbar-default bootsnav navbar-fixed-top nav-white header-light bg-transparent nav-box-width white-link on no-full nav_line">
                <div className="container nav-header-container">
                  <div className="menu-icon">
                    <a href="javascript:;">
                      <i></i>
                    </a>
                  </div>
                  <div className="row">
                    <div className="mr-15 col-md-2 col-xs-2 width-auto pull-left accordion-menu no-padding-right">
                      <div
                        className="navbar-collapse collapse pull-left"
                        id="navbar-collapse-toggle-1"
                      >
                        <ul
                          id="accordion"
                          className="nav navbar-nav navbar-left no-margin alt-font text-normal"
                          data-in="fadeIn"
                          data-out="fadeOut"
                        >
                          <li style={{ paddingLeft: 0 }}>
                            <a href="#aboutus">About Us.</a>
                          </li>
                          <li>
                            <a href="#meetus">Meet Us.</a>
                          </li>
                          <li>
                            <a href="#explore">Explore The Game.</a>
                          </li>
                          <li className="faqs">
                            <a href="#faq">FAQ.</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3   col-xs-6">
                      <a href="/" title="Logo" className="logoa  logo scroll">
                        <img
                          src="img/meta_logo.png"
                          className="rotating logo-dark"
                          alt="reone"
                        />
                        <img
                          src="img/meta_logo.png"
                          alt="reone"
                          className="rotating logo-light default"
                        />
                      </a>
                    </div>
                    <div
                      style={{ marginTop: "18px" }}
                      className="col-md-7 col-xs-6 width-auto "
                    >
                      <div className="header-soc">
                        <a
                          href="https://discord.com/invite/metagalaxyland"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src="/img/sosyal/discord-white.svg" alt="" />
                        </a>
                        <a
                          href="https://www.reddit.com/r/MetagalaxyLand/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            className="heightImg"
                            src="/img/sosyal/reddit-white.svg"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://twitter.com/metagalaxyland"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src="/img/sosyal/twitter-white.svg" alt="" />
                        </a>
                        <a
                          href="https://www.instagram.com/metagalaxyland/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src="/img/sosyal/insta-white.svg" alt="" />
                        </a>
                        <a
                          href="https://t.me/metagalaxylandofficial"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src="/img/sosyal/telegram-white.svg" alt="" />
                        </a>
                        <a
                          href="https://medium.com/@metagalaxyland"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src="/img/sosyal/medium-white.svg" alt="" />
                        </a>
                        <a
                          href="https://github.com/Metagalaxy-Land"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src="/img/sosyal/gihub-white.svg" alt="" />
                        </a>
                      </div>
                      <ul className="right_nav">
                        {blockchain.account === "" ||
                        blockchain.smartContract === null ||
                        blockchain.account === undefined ? (
                          <li>
                            <a
                              className="btn btn-connect"
                              href="/#"
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(connect());
                                getData();
                              }}
                            >
                              CONNECT WALLET
                            </a>
                          </li>
                        ) : (
                          <li>
                            <a
                              className="btn btn-connect"
                              href="/#"
                              onClick={disCon}
                            >
                              <span className="disconnectwallet">
                                <span>{truncate(blockchain.account, 5)}</span>
                              </span>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
            </header>
            {/* Start Mint Content */}
            <div className="mint-container">
              {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <div>
                  <p className="feedback">
                    <h4>Sold out!</h4>You can still find {CONFIG.NFT_NAME} on{" "}
                    <a
                      target={"_blank"}
                      rel={"noreferrer noopener"}
                      href={CONFIG.MARKETPLACE_LINK}
                    >
                      <b>{CONFIG.MARKETPLACE}</b>
                    </a>
                  </p>
                </div>
              ) : (
                <>
                  {blockchain.account === "" ||
                  blockchain.smartContract === null ||
                  blockchain.account === undefined ? (
                    <div className="mint-btn">
                      <div className="feedBox">
                        {blockchain.errorMsg !== "" ? (
                          <p className="feedback">
                            {htmlParse(blockchain.errorMsg)}
                          </p>
                        ) : null}
                      </div>
                      <button
                        className="btn btn-blue mint-btn-show"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        <span className="minticerik">CONNECT WALLET</span>
                      </button>
                    </div>
                  ) : (
                    <>
                      <div className="mint-btn-container">
                        {data.onlyWhitelisted === undefined || data.isWhitelisted === undefined ? (
                          <p className="feedback"><img src="img/loading.gif" style={{width:'30px', margin:'8px -40px', position:'absolute'}} alt="loading"/> Checking the whitelist...</p>
                        ) : (
                          <>
                            {data.onlyWhitelisted === true && data.isWhitelisted === false ? (
                              <p className="feedback">
                                <i>Only whitelisted accounts can mint.</i>
                                <br />
                                <span style={{ color: "#ef5858" }}>
                                  This account is not on the whitelist!
                                </span>
                              </p>
                            ) : (
                              <>
                                {data.onlyWhitelisted === true ? (
                                  <p className="feedback">
                                    <i>Only whitelisted accounts can mint.</i>
                                  </p>
                                ):null}
                                {feedback !== "" ? (
                                  <p className="feedback">
                                    {htmlParse(feedback)}
                                  </p>
                                ) : null}

                                <div className="mint-img">
                                  <img src={barImg} alt="" />
                                </div>
                                <div className="input-area">
                                  <button
                                    className="negative input-number-decrement"
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      decrementMintAmount();
                                    }}
                                  >
                                    <span>-</span>
                                  </button>

                                  {claimingNft ? (
                                    <span
                                      style={{ cursor: "wait" }}
                                      disabled={false}
                                    >
                                      MINT {mintAmount} PLANET
                                    </span>
                                  ) : (
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        claimNFTs();
                                        getData();
                                      }}
                                    >
                                      MINT {mintAmount} PLANET
                                    </span>
                                  )}

                                  <button
                                    className="plus input-number-increment"
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      incrementMintAmount();
                                    }}
                                  >
                                    +
                                  </button>
                                </div>
                                <div className="text-area">
                                  <span>Mint price is 199 USD</span>
                                  <small>
                                    *Maximum 20 METAGALACTIC PLANETS per
                                    transaction
                                  </small>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            {/* End Mint Content */}

            <section className="bg-black">
              <div className="container">
                <div id="meetus" className={"pb20n"}></div>
                {/* <div className="ust_text2">MEET OUR</div>
                <div className="mb-30 ust_text alt_text_green">
                  METAGALACTIC NFT<span className={"lower"}>s</span>!
                </div> */}

                <Slider {...settings}>
                  <div>
                    <div className="cardtop">
                      <img src="/img/planets/jungle/69.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="cardtop">
                      <img src="/img/planets/ice/10.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="cardtop">
                      <img src="/img/planets/jungle/70.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="cardtop">
                      <img src="/img/planets/desert/45.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="cardtop">
                      <img src="/img/planets/ocean/9.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="cardtop">
                      <img src="/img/planets/ice/11.png" alt="" />
                    </div>
                  </div>

                  <div>
                    <div className="cardtop">
                      <img src="/img/planets/desert/47.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="cardtop">
                      <img src="/img/planets/ocean/12.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="cardtop">
                      <img src="/img/planets/ice/13.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="cardtop">
                      <img src="/img/planets/ice/14.png" alt="" />
                    </div>
                  </div>
                </Slider>

                <div className="dn swiper-container">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide ">
                      <div className="bb">
                        <div className="cardtop">
                          <img src="/img/hazir/des1.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide ">
                      <div className="bb">
                        <div className="cardtop">
                          <img src="/img/planets/jungle/69.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide ">
                      <div className="bb">
                        <div className="cardtop">
                          <img src="/img/planets/ice/10.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide ">
                      <div className="bb">
                        <div className="cardtop">
                          <img src="/img/planets/jungle/70.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide ">
                      <div className="bb">
                        <div className="cardtop">
                          <img src="/img/planets/desert/45.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide ">
                      <div className="bb">
                        <div className="cardtop">
                          <img src="/img/planets/ocean/9.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide ">
                      <div className="bb">
                        <div className="cardtop">
                          <img src="/img/planets/ice/11.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide ">
                      <div className="bb">
                        <div className="cardtop">
                          <img src="/img/planets/jungle/71.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide ">
                      <div className="bb">
                        <div className="cardtop">
                          <img src="/img/planets/ocean/10.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide ">
                      <div className="bb">
                        <div className="cardtop">
                          <img src="/img/planets/desert/46.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide ">
                      <div className="bb">
                        <div className="cardtop">
                          <img src="/img/planets/ice/12.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide ">
                      <div className="bb">
                        <div className="cardtop">
                          <img src="/img/planets/jungle/72.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide ">
                      <div className="bb">
                        <div className="cardtop">
                          <img src="/img/planets/ocean/11.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide ">
                      <div className="bb">
                        <div className="cardtop">
                          <img src="/img/planets/desert/47.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide ">
                      <div className="bb">
                        <div className="cardtop">
                          <img src="/img/planets/ocean/12.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide ">
                      <div className="bb">
                        <div className="cardtop">
                          <img src="/img/planets/ice/13.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide ">
                      <div className="bb">
                        <div className="cardtop">
                          <img src="/img/planets/desert/48.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide ">
                      <div className="bb">
                        <div className="cardtop">
                          <img src="/img/planets/ocean/13.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="slidealti">
                  <div className="cardbottom ilkoge">
                    <a className="cols  cboxElement" href="#nftdata">
                      <img src="/img/ikon/btn/jungle.png" alt="" />
                      <div className="bt-1">Jungle Planet</div>
                      <div className="bt-2">Habitable Planet</div>
                      <div className="bt-3">#1.000/4.000</div>
                    </a>
                  </div>

                  <div className="cardbottom">
                    <a className="cols  cboxElement" href="#nftdata3">
                      <img src="/img/ikon/btn/ice.png" alt="" />
                      <div className="bt-1">Ice Planet</div>
                      <div className="bt-2">Habitable Planet</div>
                      <div className="bt-3">#1.000/4.000</div>
                    </a>
                  </div>

                  <div className="cardbottom">
                    <a className="cols  cboxElement" href="#nftdata2">
                      <img src="/img/ikon/btn/desert.png" alt="" />
                      <div className="bt-1">Desert Planet</div>
                      <div className="bt-2">Habitable Planet</div>
                      <div className="bt-3">#1.000/4.000</div>
                    </a>
                  </div>

                  <div className="cardbottom sonoge">
                    <a className="cols  cboxElement" href="#nftdata">
                      <img src="/img/ikon/btn/ocean.png" alt="" />
                      <div className="bt-1">Ocean Planet</div>
                      <div className="bt-2">Habitable Planet</div>
                      <div className="bt-3">#1.000/4.000</div>
                    </a>
                  </div>
                </div>
              </div>
            </section>


            <section className="p-0 welcome-section"  >
              <div class="dga dikeycizgi"> </div>
              <h4 className="ust_text_segoe mb-5-o">
                 WELCOME<br /> TO THE PRIVILEGED UNIVERSE OF <br /> <span className="alt_text_mor">NFT OWNERS!</span>
              </h4>

              <div className="container-fluid p-20-0">
                <div className="row row-list">
                    <div className="col-lg-5 item-colm">
                        <img src="/img/cerceve.png" className="bg-box" />
                        <img src="/img/icon1.png" className="icon" />
                        <p className="text">New listings, partnerships, buyback, burn and more... Space Cowboys know the news before anyone else!</p>
                    </div>
                    <div className="col-lg-5 item-colm">
                        <img src="/img/cerceve2.png" className="bg-box" />
                        <img src="/img/icon2.png" className="icon" />
                        <p className="text">Space Cowboys can get exclusive rewards for those who stake their Planet NFTs!</p>
                    </div>
                </div>

                <div className="row row-list">
                    <div className="col-lg-5 item-colm">
                        <img src="/img/cerceve.png" className="bg-box" />
                        <img src="/img/icon3.png" className="icon" />
                        <p className="text">Space Cowboys not only own their special planets, they also earn 2x Metagalaxy Points on the Metaverse Platform's leaderboard race!</p>
                    </div>
                    <div className="col-lg-5 item-colm">
                        <img src="/img/cerceve2.png" className="bg-box" />
                        <img src="/img/icon4.png" className="icon" />
                        <p className="text">Take your place in MetagalacticDAO! NFT holders have the power to decide the future of the project by voting. Remember: More planets, more votes.</p>
                    </div>
                </div>

                <div className="row row-list">
                    <div className="col-lg-5 item-colm">
                        <img src="/img/cerceve.png" className="bg-box" />
                        <img src="/img/icon5.png" className="icon" />
                        <p className="text">Exclusive giveaways and WLs for NFT holders! The opportunity to choose the reward with your votes is totally in your hands. Push the limits for hype projects!</p>
                    </div>
                    <div className="col-lg-5 item-colm">
                        <img src="/img/cerceve2.png" className="bg-box" />
                        <img src="/img/icon6.png" className="icon" />
                        <p className="text">Oh, also we all know this: Who else has a planet other than the Cowboys? With this privilege, you will be the only one profiting by terraforming the planet!</p>
                    </div>
                </div>

              </div>

            </section>

            <section className={"shiptop"}>
              <img className={"ship"} src="/img/bg/ship.png" alt="" />
            </section>

            <section id="aboutus" className="bare bg-black">
              <div className="container">
                <div className="ust_text txtyeni ">ARE YOU READY FOR </div>
                <div className="mb-30 ust_text alt_text_green txtyeni">
                  THE RIDE?{" "}
                </div>
                <br />

                <div className="ybox">
                  <div className="mb-30 white_text_centra">
                    It's time to discover a new planet, or better yet, create a
                    new planet!
                  </div>
                  <div className="mb-30 grey_text_centra">
                    MetaGalaxy Land is an NFT GameFi Metaverse project on the
                    BSC network. It is a blockchain-based metaverse experience
                    platform and where the investors are able to create and
                    exchange their own special customized planets as NFTs and
                    participate the game as a Space Cowboy.
                  </div>
                  <div className="mb-30 grey_text_centra">
                    Metagalaxy Land helps its investors who want to become a
                    Space Cowboy to create their Ideal Planet even beyond their
                    own borders. Therefore, the investors will be the creator of
                    their own Planet from its form to the society.
                  </div>
                  <div className="mb-30 white_text_centra">
                    All games are drugs, but some are more addictive than
                    others.
                  </div>
                </div>

                <div className="dikeycizgi h185" />
                <div className="slogan">
                  START SPACE
                  <br />
                  ADVENTURE
                </div>
                <div className={"center"}>
                  <img className="mt-50 w100" src="/img/gr59.png" alt="" />
                </div>
                <div className={"all-b centers"}>
                  <a
                    href="https://metagalaxyland.com/"
                    className="allabout btn-lq"
                  >
                    <span className="inner">ALL ABOUT METAGALAXY LAND</span>
                  </a>
                </div>
              </div>
            </section>

            <section
              id="explore"
              style={{ paddingBottom: "40px", minHeight: "300px" }}
              className="bg-seat bg-stars2 bg-black"
            >
              <div className={"pb20"}></div>
              <div className="container">
                <div style={{ padding: "0 28px" }} className="row">
                  <div className="br1 col-md-6 pr57">
                    <div className="top-20">
                      <div className="ust_text left-align ">
                        TAKE A SEAT AND{" "}
                      </div>
                      <div
                        style={{ marginBottom: "50px" }}
                        className="ust_text alt_text_green left-align "
                      >
                        BE ONE OF US{" "}
                      </div>
                    </div>
                    <div className="tlft grey_text3">
                      When an investor buys a Metagalaxy Land NFT, they are not
                      simply buying an avatar or a provably rare piece of art.
                      At the same time, you find a place for yourself in this
                      peerless adventure. Currently, Metagalaxy Land is a
                      collection of 4000 randomly generated NFTs.
                    </div>
                    <div className="tlft grey_text3">
                      In Metagalaxy Land Game, our investors are gaining the
                      opportunity to create a planet whose benefits and
                      offerings will increase over time and become a Space
                      Cowboy.
                    </div>
                  </div>

                  <div className="col-md-6 pl57">
                    <div className="tlft grey_text3">
                      Our NFTs are our planets in the game. Those who play Space
                      Cowboy will rule these planets. There are 4 different
                      kinds of planets, which makes those planets different from
                      each other. Each type of planet is different and they
                      offer different experiences at different levels.
                    </div>
                    <div className="tlft grey_text3">
                      As the creators of the Metagalaxy Land, we believe that
                      art is the expression of human creativity and imagination,
                      typically in a visual form. We know that world without Art
                      is just not enough so, come and join to our Galaxy with
                      your unique way of art where lies beyond the borders of
                      your creative mind.
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="astrobg bg-black2">
              <div className="container">
                <div className="row">
                  <div className="pt-120 col-md-6">
                    <div className="mb-30 tlft ust_text">
                      JOIN <span className="alt_text_mor">THE CLUB</span>
                      <div
                        style={{ width: "200px" }}
                        className="grad_sep grad_sep2"
                      />
                    </div>
                    <div className="lh-35 tlft grey_text4">
                      Join the official Metagalaxy Land Discord server for
                      exclusive updates, giveaways, a chance to connect with the
                      community and much more.
                    </div>
                    <a
                      href="https://discord.gg/jwpbk9k9Ws"
                      className="btn-liquid"
                    >
                      <span className="inner">JOIN OUR DISCORD</span>
                    </a>
                    <div
                      style={{ display: "none" }}
                      className="mt-20 pull-left"
                    >
                      <a
                        className="btn btn-mor"
                        href="https://discord.gg/jwpbk9k9Ws"
                      >
                        JOIN OUR DISCORD
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            </section>

            <section
              id="faq"
              style={{ minHeight: "auto", background: "rgb(3 5 4)" }}
              className="pb-80 bg-black"
            >
              <div className="container">
                <div className="ust_text alt_text_green left-align faq_title ">
                  FAQ{" "}
                </div>
                <div className="tabcontainer">
                  <div className="c">
                    <input type="checkbox" id="faq-1" />
                    <h1 className="alink">
                      <div className={"sss"}>
                        <label className="labels" htmlFor="faq-1">
                          Why is NFT’s are so crucial in the MetaGalaxy Land?
                        </label>
                      </div>
                    </h1>
                    <div className="p">
                      <p>
                        Our NFTs represent our planets in the game. Those who
                        take part in the game as Space Cowboy will dominate
                        these planets and progress through the levels. There are
                        4 different types of planets that make these planets
                        different from each other. Each type of planet is
                        different and offers different experiences at different
                        levels.
                      </p>
                    </div>
                  </div>

                  <div className="c">
                    <input type="checkbox" id="faq-2" />
                    <h1 className="alink">
                      <label className="labels" htmlFor="faq-2">
                        Where does my NFT go after I purchase them?
                      </label>
                    </h1>
                    <div className="p">
                      <p>
                        Your unique planet will appear in whatever address, or
                        connected wallet, you used to purchase the MetaGalaxy
                        Land.
                      </p>
                    </div>
                  </div>

                  {/* tab */}
                  <div className="c">
                    <input type="checkbox" id="faq-3" />
                    <h1 className="alink">
                      <label className="labels" htmlFor="faq-3">
                        How do I get involved?
                      </label>
                    </h1>
                    <div className="p">
                      <p>
                        The best place to start is our discord account. In this
                        way, you can adapt to the community more easily and be a
                        part of the team. All are welcome to jump into the
                        conversation, let us know your ideas, and hang out with
                        many others who are excited about discovering the
                        universe.
                      </p>
                    </div>
                  </div>
                  {/* tab */}

                  {/* tab */}
                  <div className="c">
                    <input type="checkbox" id="faq-4" />
                    <h1 className="alink">
                      <label className="labels" htmlFor="faq-4">
                        How do I get whitelisted?
                      </label>
                    </h1>
                    <div className="p">
                      <p>
                        Join our social media clubs! If you are active and show
                        us how valuable you are as a community member you might
                        have a chance to win a Whitelist spot! Also we have
                        Twitter and Discord Raffles for Whitelist spots.
                      </p>
                    </div>
                  </div>
                  {/* tab */}

                  {/* tab */}
                  <div className="c">
                    <input type="checkbox" id="faq-5" />
                    <h1 className="alink">
                      <label className="labels" htmlFor="faq-5">
                        Is MetaGalaxy Land a good investment?
                      </label>
                    </h1>
                    <div className="p">
                      <p>
                        Actually, it's a decision you have to make. MetaGalaxy
                        is a constantly growing and developing project and aims
                        to be here for the long run. We are committed to working
                        for our investors and their thoughts are very crucial to
                        us. In this process, we empathize and put ourselves in
                        the position of investors and answer any questions they
                        may have.
                      </p>
                    </div>
                  </div>
                  {/* tab */}
                </div>
                <div
                  style={{ marginLeft: 0, marginTop: "30px" }}
                  className="gr22 "
                />
              </div>
            </section>

            <footer className="bgnew padding-40pxb bg-black2">
              <div className="container">
                <div className="row">
                  <div className="mt-0 pl0 mt-30 col-sm-4">
                    <div className="flex centered">
                      <div className="alt_purple">Contact Us</div>
                      <div className="mailimg">
                        <img src="/img/ikon/mails.png" alt="" />
                      </div>
                      <div className="foot_input">
                        {" "}
                        <a
                          className={"whites"}
                          href="mailto:nft@metagalaxyland.com"
                        >
                          <span className="mailhover">
                            nft@metagalaxyland.com
                          </span>
                        </a>{" "}
                      </div>
                    </div>
                  </div>

                  <div className="text_center col-sm-5 no-padding">
                    <img className="foot_logo" src="/img/logo2.png" alt="" />
                  </div>

                  <div className="col-sm-3 pr0">
                    <div className="footer-soc">
                      <a
                        href="https://discord.com/invite/metagalaxyland"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className={"sos discord"}>...</div>{" "}
                      </a>
                      <a
                        href="https://twitter.com/metagalaxyland"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className={"sos twitter"}>...</div>{" "}
                      </a>
                      <a
                        href="https://www.instagram.com/metagalaxyland"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className={"sos instagram"}>...</div>{" "}
                      </a>
                      <a
                        href="https://t.me/metagalaxylandofficial"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className={"sos telegram"}>...</div>{" "}
                      </a>
                      <a
                        href="https://medium.com/@metagalaxyland"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className={"sos medium"}>...</div>{" "}
                      </a>
                      <a
                        href="https://github.com/Metagalaxy-Land"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className={"sos github"}>...</div>{" "}
                      </a>
                    </div>
                    <p className="footer_p">
                      © 2022 METAGALAXY | All Rights Reserved
                    </p>
                    <p className="gren_text">TERMS &amp; CONDITIONS</p>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
}
