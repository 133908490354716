import React from 'react';
import ReactDOM from 'react-dom';
import store from "./redux/store";
import App from './App';
import { Provider } from "react-redux";

// ReactDOM.render( render hatası var
//     <Provider store={store}>
//         <App />
//     </Provider>,
//     document.getElementById("root")
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
    <App />
    </Provider>
);